import * as React from "react";
import styled from "styled-components";
import Accordion from "react-collapsy";
import { useState } from "react";
import "react-collapsy/lib/index.css";

import { Container } from "@util/standard";
import {
  assets,
  colors,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { Maybe, SanitySpecificBlock } from "@graphql-types";
import { BlocksContent } from "@global";

const Wrapper = styled(Container)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  justify-content: center;
  background-color: ${colors.darkBlue};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100%;
  }
`;

const StyledAccordion = styled(Accordion)<{
  isLast: boolean;
  isFirst: boolean;
  disableExpandedView: boolean;
}>`
  border: none;
  border-bottom: 1px solid rgba(100, 100, 100, 0.9);
  margin-bottom: 10px;
  ${({ isLast }) => isLast && `border-bottom: none;`};

  .Accordion__header {
    background: transparent;
    color: ${colors.white};
    padding-left: 0px;
    cursor: pointer;
    width: 80%;

    ::after {
      filter: brightness(1);
      background: url(${assets.plusLogo});
      background-repeat: no-repeat;
      margin: auto;
      background-size: 100% 100%;
      transform: rotate(45deg);
    }
  }
  .Accordion__header--collapsed:after {
    transform: rotate(0deg);
  }
  .Accordion__header--collapsed {
    padding-bottom: 10px;
    ${({ isFirst }) => isFirst && `align-items: unset; padding-bottom: 0px;`};
  }

  .Accordion__body {
    padding: 0;
    color: ${colors.white};

    ul {
      padding-left: 15px;
    }
  }
`;

const LeftContainer = styled(Container)`
  width: 34%;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-bottom: 15%;
  }
`;

const ContentContainer = styled(Container)`
  width: 67%;
  justify-content: space-between;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    margin-bottom: 3%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    margin-bottom: 10%;
  }
`;

const RightContainer = styled(Container)`
  width: 50%;
  overflow: auto;
  flex-direction: column;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

interface Props {
  accordionData?: Maybe<SanitySpecificBlock> | undefined;
}

const AccordionBlock = ({ accordionData }: Props) => {
  const [currentExpandedItem, setCurrentExpandedItem] = useState<number>();

  if (accordionData == null) {
    return null;
  }

  return (
    <Wrapper>
      <ContentContainer>
        <LeftContainer>
          <BlocksContent
            blocks={accordionData?.accordionMainContent?._rawColumnContent}
          />
        </LeftContainer>
        <RightContainer>
          {accordionData?.accordionContent?.map((content, index) => {
            if (accordionData?.accordionContent == undefined) {
              return null;
            }
            const isLast =
              index === accordionData?.accordionContent?.length - 1;
            const isFirst = index === 0;
            return (
              <Container
                onClick={() => setCurrentExpandedItem(index)}
                key={content?._key}
              >
                <StyledAccordion
                  title={content?.contentTitle}
                  isLast={isLast}
                  isFirst={isFirst}
                  key={content?._key}
                >
                  {!(index !== currentExpandedItem) && (
                    <BlocksContent blocks={content?._rawColumnContent} />
                  )}
                </StyledAccordion>
              </Container>
            );
          })}
        </RightContainer>
      </ContentContainer>
    </Wrapper>
  );
};

export default AccordionBlock;
