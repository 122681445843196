import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import AboutHero from "@components/about/aboutHero";
import { Query } from "@graphql-types";
import QuoteBlock from "@shared/quoteBlock";
import AccordionBlock from "@components/about/accordionBlock";
import LeftRightBlock from "@shared/leftRightBlock";
import Timeline from "@components/about/timeline";
import SEO from "@shared/seo";

const AboutPage = () => {
  const { sanityAboutUsPage }: Query = useStaticQuery(graphql`
    {
      sanityAboutUsPage {
        quoteBlock {
          _rawColumnContent
        }
        specificBlock {
          accordionMainContent {
            _rawColumnContent
          }
          accordionContent {
            contentTitle
            _key
            _rawColumnContent
            _type
          }
        }
        content {
          ...sanityLeftRightBlock
        }
        timeLine {
          _rawColumnContent
          _key
          _type
          contentLink {
            _key
            _type
            internallink
            linktext
            newwindow
            url
          }
        }
        seo {
          ...sanitySeo
        }
      }
    }
  `);

  if (sanityAboutUsPage == null) {
    return null;
  }

  return (
    <>
      <SEO seoData={sanityAboutUsPage?.seo} />
      <AboutHero />
      <QuoteBlock quoteData={sanityAboutUsPage?.quoteBlock} />
      <AccordionBlock accordionData={sanityAboutUsPage?.specificBlock} />
      {sanityAboutUsPage?.content?.map((item) => {
        return (
          <LeftRightBlock data={item} key={item?._key} isAbout />
        );
      })}
      <Timeline timelineData={sanityAboutUsPage?.timeLine} />
    </>
  );
};

export default AboutPage;
