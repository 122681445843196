import React from "react";
import styled from "styled-components";

import { colors, IPAD_BREAKPOINT, IPAD_PRO_BREAKPOINT } from "@util/constants";
import { SanityColumn, Maybe } from "@graphql-types";
import { BlocksContent, Button } from "@global";
import { Container, GiveMeSomeSpace } from "@util/standard";
import { linkToButton } from "@util/helper";

const Wrapper = styled(Container)`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: visible;
  background-color: ${colors.darkBlue};
  flex-wrap: wrap;
`;

const Circle = styled.div<{
  margin?: string;
}>`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  transition-timing-function: ease-in;
  transition: all 1s;
  background-color: white;
  ${({ margin }) => margin && `margin: ${margin};`}
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 10px;
    height: 10px;
  }
`;

const RightLine = styled(Container)`
  width: 15px;
  height: 1px;
  border-top: 1px solid ${colors.white};
  margin-top: 20%;
`;

const MobileContainer = styled(Container)`
  flex-direction: row;
  margin-left: -6px;
  margin-bottom: 5%;
  width: -webkit-fill-available;
`;

const MobileContent = styled(Container)`
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  margin: 10%;
  h4 {
    font-size: 18px;
  }
  @media only screen and (min-width: ${IPAD_BREAKPOINT}px) {
    margin-bottom: 5%;
    margin-top: 0%;
  }
`;

const InfoContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.8);
`;

interface Props {
  timelineData?: Maybe<Maybe<SanityColumn>[]> | undefined;
}

const Timeline = ({ timelineData }: Props) => {
  if (timelineData == null) return null;

  const data = timelineData.slice(0, -1);
  const lastTimelineItem = timelineData[timelineData.length - 1];
  const lastItemButton = linkToButton(lastTimelineItem?.contentLink);

  const RightLines = ({ index }: { index: number }) => {
    return (
      <Container justifyContent="flex-end">
        <Circle />
        {index === 0 ? <GiveMeSomeSpace space={8} /> : <RightLine />}
      </Container>
    );
  };

  return (
    <Wrapper>
      <MobileContent>
        <Container
          width="100%"
          justifyContent="center"
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <InfoContainer>
            {data?.map((item, index) => {
              if (item == null) return null;
              const { _key, _rawColumnContent, contentLink } = item;
              const link = linkToButton(contentLink);

              return (
                <MobileContainer key={_key}>
                  <RightLines index={index} />
                  <Container
                    width="75%"
                    margin="auto auto 5% 10%"
                    flexDirection="column"
                  >
                    <BlocksContent blocks={_rawColumnContent} />
                    {contentLink?.linktext && (
                      <Button
                        {...link}
                        theme="text"
                        arrowWidth="15px"
                        isNormalFont
                        noTextUnderlineOffset
                        underlined="underline 1px"
                        mobilefontSize={11}
                        arrowMargin="auto auto auto 8px"
                      />
                    )}
                  </Container>
                </MobileContainer>
              );
            })}
          </InfoContainer>

          <Container width="100%" margin="0 0 0 -10px">
            <RightLines index={-1} />
            <Container
              width="75%"
              margin="auto auto 5% 10%"
              flexDirection="column"
            >
              <BlocksContent blocks={lastTimelineItem?._rawColumnContent} />
              {lastTimelineItem?.contentLink?.linktext && (
                <Button
                  {...lastItemButton}
                  theme="text"
                  arrowWidth="15px"
                  isNormalFont
                  noTextUnderlineOffset
                  underlined="underline 1px"
                  mobilefontSize={11}
                  arrowMargin="auto auto auto 8px"
                />
              )}
            </Container>
          </Container>
        </Container>
      </MobileContent>
    </Wrapper>
  );
};

export default Timeline;
