import React from "react";
import { useStaticQuery, graphql } from "gatsby";


import Hero from "@shared/hero";
import { Query } from "@graphql-types";

const AboutHero = () => {
  const { sanityAboutUsPage }: Query = useStaticQuery(graphql`
    {
      sanityAboutUsPage {
          hero {
            ...sanityHero
          }
        }
    }
  `);

  return <Hero data={sanityAboutUsPage?.hero} height="67vh" />;
};

export default AboutHero;
